@font-face {
  font-family: "Prompt";
  src: local("Prompt"), url(assets/fonts/Prompt-Regular.ttf) format("truetype");
}
html,
body {
  margin: 0;
  font-family: Prompt !important;
}

/* default */
.req {
  color: red;
}
.label-input {
  font-size: 22px !important;
  /* font-weight: bold !important; */
}
.input-disabled {
  background-color: #e6e6e6 !important;
  border: none !important;
  border-radius: 5px;
}
.input-disabled > div > fieldset.MuiOutlinedInput-notchedOutline {
  border: 0 !important;
}
.radio-text > span {
  font-size: 22px;
  font-weight: "bold";
}
.bg-red {
  border: 1px solid red !important;
}
.bg-b {
  border: 1px solid blue !important;
}
.primary-color {
  color: #0a52a4;
}
.text-black {
  color: #000000;
}
.text-active {
  color: #37a000 !important;
}
.text-inactive {
  color: #ff0000 !important;
}
.text-wait {
  color: #fd8228 !important;
}

/* custom table styles */
.table-header {
  background-color: #000000;
  font-size: 25px;
}
.table-header > tr > th {
  color: white;
  height: 60px !important;
  /* font-weight: bold; */
  font-size: 20px !important;
}
table > tbody > tr > td {
  /* font-weight: bold !important; */
  font-size: 20px !important;
}
.MuiTableContainer-root {
  box-shadow: none !important;
}
tr:nth-child(n) > th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
tr:nth-child(n) th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
tr:nth-child(n) > td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
tr:nth-child(n) td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
/* end table */

/* nav */
.no-padding-list-profile > div > ul {
  padding-top: 0;
  padding-bottom: 0;
}
.no-padding-list-profile > div {
  border-radius: 0;
}
.MuiListItem-root > .MuiTouchRipple-root {
  background-color: red !important;
}
.MuiListItem-root > div.Mui-selected > div {
  background-color: white;
}

nav > .MuiListItemButton-root:hover {
  background: #ddd !important;
}
nav > a > div:hover {
  background: #ddd !important;
}
.sidebar > a > div.Mui-selected {
  background: white !important;
}
.MuiListItem-root.MuiListItem-gutters:hover {
  background: #333 !important;
}
.sidebar-inactive:hover {
  background-color: #333 !important;
}
.sidebar-selected.Mui-selected:hover {
  background-color: #363636 !important;
}
.sidebar-selected:hover {
  background-color: #363636 !important;
}
/*end nav profile */

.placeholder-overflow > input::placeholder {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.sidebar-list {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  /* background-color: red !important; */
}
.sidebar-list a::after {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.sidebar-sub-list {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  padding-bottom: 20px !important;
}
/* button submit */
.btn-grad {
  background: transparent linear-gradient(255deg, #0db1ee 0%, #0b66b3 58%, #0a52a4 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 39px;
  opacity: 1;
}
.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}
.testcolor {
  color: #fc8b00;
}
/*end button submit */

/* select icon color */
.select-icon-primary > svg {
  color: #0a52a4;
}
.dotted {
  border: 1px dashed #222;
}

:root {
  --clr-border: #333;
}
.box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 2em;
  padding: 0.5em;
  line-height: 1;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-size: 2px 100%, 100% 2px, 3px 100%, 100% 3px;
  background-repeat: no-repeat;
}
.box-a {
  background-image: repeating-linear-gradient(0deg, var(--clr-border), var(--clr-border) 0, transparent 0, transparent 0),
    repeating-linear-gradient(90deg, var(--clr-border), var(--clr-border) 10px, transparent 10px, transparent 20px);
  border-image: repeating-linear-gradient(0deg, var(--clr-border), var(--clr-border) 10px, transparent 10px, transparent 20px);
}
